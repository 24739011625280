import React from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import RichText from 'components/RichText';
import Container from 'react-bootstrap/Container';
import LearnPost from 'components/LearnPost';
import { extractSocialMeta } from 'utils/fieldExtractors';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

function DiscoveryArticle({ data }) {
  const doc = get(data, 'appPrismic.discoveryArticle');
  if (!doc) {
    return null;
  }

  const { meta, title, seoTitle, seoMeta } = doc;

  return (
    <Layout>
      <SEO
        title={seoTitle || get(title, '[0].text', '')}
        description={seoMeta}
        lang={meta}
        socialMeta={extractSocialMeta(doc)}
      />
      <Container>
        <LearnPost article={doc} />
      </Container>
    </Layout>
  );
}

export const appQuery = graphql`
  query DiscoveryArticleQuery($uid: String!, $lang: String!) {
    appPrismic {
      discoveryArticle: discovery_article(uid: $uid, lang: $lang) {
        ...DiscoveryArticleFragment
      }
    }
  }
`;

DiscoveryArticle.propTypes = propTypes;

export default DiscoveryArticle;
